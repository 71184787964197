.right-dialog-container {
    height: 100%;
    width: 360px;
    display: flex;
    flex-direction: column;
    /* padding: 16px 24px 16px 0px; */
    
		border-right: solid#CFD8DC 1px;

    background-color: white;
    z-index: 9;
}


.print-container-title-text {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: 0.4px;
    text-transform: uppercase;

    /* Common/Black/100p */

    color: #000000;
    flex: 1;
}

.button-print-title-icons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: fit-content;
    align-items: center;
}

.button-print-title {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: inherit;
    align-items: center;
}

.right-dialog-header-container {
    display: flex;
    height: 60px;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: #CFD8DC;
}

.button-print-container {

    padding: 24px 0px;

    .btn-cancel-container{
        display: flex;
        gap: 12px;
    }

    .print-action-basic {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: calc(100% - 32px);
				margin-left: 16px;
				margin-right: 16px;
        height: 36px;
        background: #5C6BC0;
        border-radius: 48px;
        display: flex;

        &.puese {
            width: 50%;
        }

        &.stop {
            width: 50%;
            background: #FFFFFF;
            border-width: 1px;
            border-style: solid;
            border-color: rgba(0, 0, 0, 0.12);
        }

        .print-action-text {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            color: #FFFFFF;

            &.stop {
                color: #5C6BC0;
            }
        }
        &:disabled{
            opacity: 0.7;
        }
    }

    .stop-print-action-basic {}
}

.items-container {

		flex-grow: 1;

    /* overflow-y: auto; */

    .print-container-basic {
        /* height: 72px; */
        background-color: rgba(92, 107, 192, 0.1);
        flex-direction: row-reverse;
        /* Blue Gray/50 */
        width: calc(100% - 32px);
        border: 1px solid #ECEFF1;
        border-radius: 8px;
        padding: 16px 24px;
        margin: 16px;
        
        justify-content: space-between;
        align-items: center;
        display: flex;
        transition: height 0.4s;

    }

    .btn-clear-container {
        padding: 16px 0px;
        .btn {
            color: var(--100, #5C6BC0);

            .clear {
                font-family: Rubik;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.4px;
                text-transform: uppercase;
            }
        }
    }
}

.print-container {
    display: flex;
    flex-direction: column;
}

.right-dialog-header-button {
    cursor: pointer;
    border-radius: 8px;

    &:hover {

        background-color: #e5e7e9;

    }
}

.print-container-error-data {
    display: flex;
    flex-direction: column;
    /* align-content: flex-end; */
    align-items: flex-end;
    width: inherit;

    .print-container-error-data-row {
        display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        width: inherit;
        justify-content: space-between;
        flex-direction: row-reverse;

        .print-container-error-data-row-title {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 166%;
            /* identical to box height, or 20px */

            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: 0.4px;
            
            color: #000000;
            &.desc{
                text-align: left;
            }
        }
    }
}

.status {
    display: flex;
    height: 32px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: var(--red-600, #E53935);
    text-align: center;

    .text {
        /* Typography/Body 2 */
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 143%;
        /* 20.02px */
        letter-spacing: 0.17px;
    }

    &.success{
    color: var(--red-600, green);
    }
}