.table-container {
    display: flex;
    flex-direction: row-reverse;

		height: 100%;

    .table {
				position: relative;

        flex: 1;

				height: 100%;
				display: flex;
				flex-direction: column;
				margin-left: 50px;
    }
}

.MuiDataGrid-root{
    direction: rtl;
		border: none !important;

		/* @TODO make sure firefox and safari doesnt show the scrollbar */
		scrollbar-width: none;

		& ::-webkit-scrollbar {
			width: 0px;
			
		}
}

.MuiDataGrid-columnHeaderRow{
    background: rgba(236, 239, 241, 0.5);
    border-radius: 8px;
}

.MuiDataGrid-columnHeadersInner{
    background: rgba(236, 239, 241, 0.5);
    border-radius: 8px;
		width: 100%;
		
		div {
			width: 100%;
		}
}

.MuiDataGrid-columnHeaders {
	border: none !important;
}

.MuiDataGrid-columnSeparator {
	opacity: 0 !important; 
}

.MuiDataGrid-virtualScroller {
	overflow-x: hidden !important;

	.Mui-selected {
		background-color: #5C6BC02B !important;
		border-radius: 8px;
	}
}

.MuiDataGrid-row {
	margin-top: 4px;
	margin-bottom: 4px;
	border-bottom: solid 1px var(--blue-gray-50, #ECEFF1);;
}

.MuiDataGrid-row:first-child {
	margin-top: 8px;
}

.MuiDataGrid-cell {
	border: none !important;
}

.MuiDataGrid-iconButtonContainer {
    visibility: hidden !important;
}

.btn-container{
	position: absolute;
  display: flex;

	bottom: 60px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 10;
}

.MuiDataGrid-footerContainer{
    direction: ltr;
}