.filter-container {}

.filter-title-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid var(--blue-gray-50, #ECEFF1);
    background: #FFF;
    /* padding: 11px 40px; */
    padding: 11px 40px 11px 30px;

    .filter-title {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 175%;
        /* identical to box height, or 28px */

        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: 0.15px;
        color: #000000;
    }

    .filter-close {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        .clean-text {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 171% */

            letter-spacing: 0.4px;
            text-transform: uppercase;

            /* Blue Gray/200 */

            color: #B0BEC5;
        }
    }
}

.filter-items-container {
    display: flex;
    flex-direction: column;
    padding: 16px 40px;

    .filter-item {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 10px;
        margin-top: 10px;

        .filter-item-title {
            width: 20%;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 157%;
            /* identical to box height, or 22px */

            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-align: right;
            letter-spacing: 0.1px;

            color: #000000;

        }

        .filter-item-component {
            width: 100%;

            .price-container {
                display: flex;
                flex-direction: row-reverse;
                gap: 16px;
                .price{
                    flex: 1;
                }
            }

            .date-container {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                column-gap: 16px;
            }

        }
    }
}

.filter-btns-container {
    padding: 0px 40px 16px;

    .btn {
        color: var(--primary-sf-100, #002E94);
        font-family: Rubik;
        font-weight: 500;

        .clean {
            color: var(--red-600, #E53935);

        }
    }
}